import consumer from "./consumer"

const adminDashboardBox = document.querySelector('[data-admin-dashboard]')

if (adminDashboardBox) {

  const account_id = adminDashboardBox.dataset.adminDashboard;

  consumer.subscriptions.create({channel: "AdminDashboardChannel", account_id: account_id }, {
    connected() {
      // Called when the subscription is ready for use on the server
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(data) {

      let all_members_ids = Array.from($('.attendees-statuses [data-member-id]')).map( item => item.dataset['memberId'])
      let active_members = all_members_ids.filter(value => data.includes(value))
      let non_active_members = all_members_ids.filter(value => !data.includes(value))

      active_members.forEach( member_id => {
        $(`.attendees-statuses [data-member-id='${member_id}']`).removeClass('d-none')
      })

      non_active_members.forEach( member_id => {
        $(`.attendees-statuses [data-member-id='${member_id}']`).addClass('d-none')
      })


    }
  });
}