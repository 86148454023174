import consumer from "./consumer"

const meetingBox = document.querySelector('[data-meeting-channel]')
if (meetingBox) {
  const meeting_id = meetingBox.dataset.meetingChannel
  const item_step = meetingBox.dataset.meetingStep
  const item_id = meetingBox.dataset.meetingItem
  const sender = meetingBox.dataset.sender;

  consumer.subscriptions.create({channel: "ItemCommentChannel", item_id: item_id}, {
    connected() {
      console.log("Connected to the item comments!");
      // Called when the subscription is ready for use on the server
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(data) {

      if (data.sender != sender) {
        $('#comments').append(data.content)
      }
      $('#toasts').append(data.toast)
      $(`#toast-${data.toast_id}`).toast('show');
      // Called when there's incoming data on the websocket for this channel
    }
  });
}