// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("trix")
require("@rails/actiontext")
require("controllers")
require("@fortawesome/fontawesome-free/js/all")

const flatpickr = require("flatpickr");
const Uppy = require('@uppy/core')
const Dashboard = require('@uppy/dashboard')
const ActiveStorageUpload = require('@excid3/uppy-activestorage-upload')
const XHRUpload = require('@uppy/xhr-upload')
import {Sortable} from '@shopify/draggable';

function enableDraggable() {
    const containerSelector = '#board-pack-container';
    const containers = document.querySelectorAll(containerSelector);

    if (containers.length === 0) {
        return false;
    }

    const sortable = new Sortable(containers, {
        draggable: '.draggable-element',
        handle: '.draggable-handler',
        mirror: {
            appendTo: containerSelector,
            constrainDimensions: true,
        },
    });
    sortable.on('sortable:stop' , (evt) => {

        const items = $('#board-pack-container li').not('.draggable--original, .draggable-mirror').map( (i, el) => ({id: $(el).data('board-pack-item-id'), position: i+1}))

        fetch($('#board-pack-container').data('save-url'), {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify({items: Array.from(items)})})
    });
}

enableDraggable()

window.Uppy = Uppy;
window.Dashboard = Dashboard;
window.XHRUpload = XHRUpload;

const scrollToBottom = (element) => {
    var objDiv = document.getElementById(element);
    if(objDiv) {
        objDiv.scrollTop = objDiv.scrollHeight;
    }
}

window.scrollToBottom = scrollToBottom;

document.querySelectorAll('[data-uppy]').forEach(element => enableUppy(element))



$('.chat_toggle').on('click', function(e) {

    e.preventDefault()
    if ($(this).closest('#chat_window').is('.hidden')) {
        $(this).closest('#chat_window').removeClass('hidden');
        window.scrollToBottom('chat_area');
        $('#meetings_message_content').focus();
        localStorage.setItem('chat_is_opened', "yes")
    } else {
        localStorage.setItem('chat_is_opened', "no")
        $(this).closest('#chat_window').addClass('hidden');
    }
});

$(document).ready( function() {
    if ($('#chat_window').length > 0 && (localStorage.getItem('chat_is_opened') == "yes" || localStorage.getItem('chat_is_opened') == null)) {
        $('#chat_window').removeClass('hidden')
        window.scrollToBottom('chat_area');
    }
})


function enableUppy(element)
{
    const uppy = new Uppy()
        .use(Dashboard, {
            proudlyDisplayPoweredByUppy: false,
            closeAfterFinish: true,
            trigger: `[data-uppy-id='${element.getAttribute('data-uppy-id')}'] [data-behavior='uppy-trigger']`
        })
        .use(XHRUpload, {
            bundle: true,
            endpoint: element.querySelector("[data-behavior='uppy-trigger']").getAttribute('data-uppy-url')})

    uppy.on('complete', (result) => {
        $(`#item-${result.successful[0].response.body.item} .documents-list, .items-documents-list`).html(result.successful[0].response.body.documents_html)
    })
}

document.querySelectorAll('[data-board-pack-uppy]').forEach(element => enableBoardPackUppy(element))


function enableBoardPackUppy(element)
{
    const uppy = new Uppy()
        .use(Dashboard, {
            proudlyDisplayPoweredByUppy: false,
            trigger: `[data-board-pack-uppy-id='${element.getAttribute('data-board-pack-uppy-id')}'] [data-behavior='uppy-trigger']`
        })
        .use(XHRUpload, {
            bundle: true,
            endpoint: element.querySelector("[data-behavior='uppy-trigger']").getAttribute('data-uppy-url')})

    uppy.on('complete', (result) => {
        $(`#board-pack-container`).html(result.successful[0].response.body.attachments_html)
})
}


$(document).ready( function() {

   $('#meetings_message_content').on('keydown', (e) => {

        if(e.keyCode == 13){
        e.preventDefault()
        form = document.querySelector('#chat_form_element');
        form.dispatchEvent(new Event('submit', {bubbles: true}));
        }
    });

    window.scrollToBottom('chat_area')
    $(".datepicker").flatpickr({});
    $('.timepicker').flatpickr({
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true
    });

    if ($('#sections').length > 0) {
        let observer = new MutationObserver(mutationRecords => {
                document.querySelectorAll('[data-uppy]').forEach(element => enableUppy(element))
        });

        observer.observe($('#sections').get(0), {
            childList: true, // observe direct children
            subtree: false, // and lower descendants too
            characterDataOldValue: true // pass old data to callback
        });
    }
})

require('@uppy/core/dist/style.css')
require('@uppy/dashboard/dist/style.css')

/*
document.querySelectorAll('[data-uppy]').forEach(element => setupUppy(element))


function setupUppy(element) {
    let trigger = element.querySelector('[data-behavior="uppy-trigger"]')
    //let form = element.closest('form')
    let direct_upload_url = document.querySelector("meta[name='direct-upload-url']").getAttribute("content")
    let field_name = element.dataset.uppy

    trigger.addEventListener("click", (event) => event.preventDefault())

    let uppy = Uppy({
        autoProceed: true,
        allowMultipleUploads: true,
        logger: Uppy.debugLogger
    })

    uppy.use(ActiveStorageUpload, {
        directUploadUrl: direct_upload_url
    })

    uppy.use(Dashboard, {
        trigger: trigger,
        closeAfterFinish: true,
    })

    uppy.on('complete', (result) => {
        // Rails.ajax
        // or show a preview:
        element.querySelectorAll('[data-pending-upload]').forEach(element => element.parentNode.removeChild(element))

    result.successful.forEach(file => {
        appendUploadedFile(element, file, field_name)
        setPreview(element, file)
    })

    uppy.reset()
})
}

function appendUploadedFile(element, file, field_name) {
    const hiddenField = document.createElement('input')

    hiddenField.setAttribute('type', 'hidden')
    hiddenField.setAttribute('name', field_name)
    hiddenField.setAttribute('data-pending-upload', true)
    hiddenField.setAttribute('value', file.response.signed_id)

    element.appendChild(hiddenField)
}

function setPreview(element, file) {
    let preview = element.querySelector('[data-behavior="uppy-preview"]')
    if (preview) {
        let src = (file.preview) ? file.preview : "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSpj0DBTVsaja01_xWh37bcutvpd7rh7zEd528HD0d_l6A73osY"
        preview.src = src
    }
}

window.setupUppy = setupUppy;
*/

var jQuery = require("jquery");

// import jQuery from "jquery";
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

require("bootstrap");
require("jquery-ui")
require('./bootstrap-editable')

const availableCities = ['Baltimore', 'New York'];
$('#cityField').autocomplete( { source: availableCities } );
$('#calendarField').datepicker( { dateFormat: 'yy-mm-dd' } );

$( function() {
    $( "#sortable" ).sortable();
    $( "#sortable" ).disableSelection();
} );

$('.dropdown-item.edit').on('click', (e) => {
    e.preventDefault()
    $('#myModal').modal({})
});

$('#username').editable({mode:'inline'});

$('.add-item').on('click', (e) => {

    e.preventDefault();
    $('#sortable').append('<li class="ui-state-default"><span class="ui-icon ui-icon-arrowthick-2-n-s"></span>Item 10</li>')
});

/*
change: function( event, ui ) {
    // do your stuff here.
}
*/

console.log('aaaa')
console.log($('h1').text())

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


