import consumer from "./consumer"

const meetingBox = document.querySelector('[data-meeting-channel]')
if (meetingBox) {
  const meeting_id = meetingBox.dataset.meetingChannel
  const item_step = meetingBox.dataset.meetingStep
  const item_id = meetingBox.dataset.meetingItem

  consumer.subscriptions.create({channel:"MeetingChannel", meeting_id: meeting_id }, {

    // Called once when the subscription is created.
    initialized() {
      console.log('initialized')
      this.update = this.update.bind(this)
    },

    update() {
      this.documentIsActive() ? this.appear() : this.away()
    },

    away() {
      console.log('i am away')
      this.perform("away", { 'meeting_id' : meeting_id, item_id: item_id, item: item_step })
    },
    appear() {
      console.log('hurray, i am appeared')
      this.perform("appear", { 'meeting_id' : meeting_id, item_id: item_id, item: item_step })
    },
    // Called when the subscription is ready for use on the server.
    connected() {

      console.log("Connected to the meeting!");
      this.install()
      this.update()
      // Called when the subscription is ready for use on the server
    },

    // Called when the WebSocket connection is closed.
    disconnected() {
      console.log('disconnected')
      // Called when the subscription has been terminated by the server
    },


    // Called when the subscription is rejected by the server.
    rejected() {
      this.uninstall()
    },

    install() {
      window.addEventListener("focus", this.update)
      window.addEventListener("blur", this.update)
      //document.addEventListener("turbolinks:load", this.update)
      document.addEventListener("visibilitychange", this.update)
    },

    uninstall() {
      window.removeEventListener("focus", this.update)
      window.removeEventListener("blur", this.update)
      //document.removeEventListener("turbolinks:load", this.update)
      document.removeEventListener("visibilitychange", this.update)
    },

    speak() {

    },

    received(data) {

      $('.attendees-statuses [data-member-id]').each((i, el) => {
        const member_id = $(el).data('member-id')
        const attendee = data.find( (el) => el.board_member_id == member_id)
        if (attendee === undefined) {
          $(el).find('span').text('inactive')
          $(el).find('span').removeClass('badge-success badge-warning')
          $(el).find('span').addClass('badge-dark')
        } else {

          $(el).find('span').text(`${attendee.status} / ${attendee.item}`)
          $(el).find('span').removeClass('badge-success badge-warning badge-dark')
          if (attendee.status == 'idle') {
            $(el).find('span').addClass('badge-warning')
          } else {
            $(el).find('span').addClass('badge-success')
          }
        }
      });
      // Called when there's incoming data on the websocket for this channel
    },

    documentIsActive() {
      return document.visibilityState == "visible" //&& document.hasFocus()
    },

    get appearingOn() {
      const element = document.querySelector("[data-appearing-on]")
      return element ? element.getAttribute("data-appearing-on") : null
    },

  });
}
