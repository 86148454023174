import consumer from "./consumer"

const meetingBox = document.querySelector('[data-meeting-channel]')
if (meetingBox) {
  const meeting_id = meetingBox.dataset.meetingChannel
  const item_step = meetingBox.dataset.meetingStep
  const item_id = meetingBox.dataset.meetingItem
  const sender = meetingBox.dataset.sender;

  consumer.subscriptions.create({channel: "MeetingMessageChannel", meeting_id: meeting_id }, {
    connected() {
      console.log('connected to messages!');
      // Called when the subscription is ready for use on the server
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      if (data.sender != sender) {
        $('#chat_messages').append(data.content)
      }
      window.scrollToBottom('chat_area')
      // Called when there's incoming data on the websocket for this channel
    }
  });
}